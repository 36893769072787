import './App.css';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { saveAs } from 'file-saver';
import {
  Box, Grid, TextField, Button, TableContainer, Table, TableHead, TableBody, TableRow, CircularProgress,
  TableCell, FormControl, InputLabel, Select, MenuItem, Typography
} from '@mui/material';
import pineappleImage from './pineapple_invoice.png';
import ReactGA from 'react-ga';
import DeleteIcon from '@mui/icons-material/Delete';

const backendUrl = process.env.NODE_ENV === 'production'
  ? 'https://skapafaktura-backend.herokuapp.com'
  : 'http://localhost:3001';

function App() {

  //Google Anatlytics nedan
  useEffect(() => {
    ReactGA.initialize('G-T6LDYXJL2G');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [loading, setLoading] = useState(false);
  const [yourCompanyName, setYourCompanyName] = useState('');
  const [yourAddress, setYourAddress] = useState('');
  const [yourPostcodeAndCity, setYourPostcodeAndCity] = useState('');
  const [yourOrgNr, setYourOrgNr] = useState('');
  const [yourBank, setYourBank] = useState('');
  const [yourVATnr, setYourVATnr] = useState('');

  const [invoiceRows, setInvoiceRows] = useState([]);
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [postcodeAndCity, setPostcodeAndCity] = useState('');

  const [vat, setVat] = useState('25');
  const [invoiceNumber, setInvoiceNumber] = useState('');

  const [dueDate, setDueDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    return date.toISOString().substr(0, 10);
  });

  const [paymentTerms, setPaymentTerms] = useState('30');
  const [showTable, setShowTable] = useState(false);
  const addRow = (event) => {
    event.preventDefault();

    if (invoiceRows.length >= 15) {
      return;
    }

    const newRow = {
      description: description,
      quantity: quantity,
      price: price,
    };
    setInvoiceRows([...invoiceRows, newRow]);
    setDescription('');
    setQuantity('');
    setPrice('');
    setShowTable(true);
  };

  const createPdf = (event) => {
    event.preventDefault();
    setLoading(true);
    const testData = {
      yourCompanyName: yourCompanyName,
      yourAddress: yourAddress,
      yourPostcodeAndCity: yourPostcodeAndCity,
      yourOrgNr: yourOrgNr,
      yourBank: yourBank,
      yourVATnr: yourVATnr,
      companyName: companyName,
      address: address,
      postcodeAndCity: postcodeAndCity,
      invoiceRows: invoiceRows,
      invoiceNumber: invoiceNumber,
      dueDate: dueDate,
      paymentTerms: paymentTerms,
      vat: vat,
    };

    fetch(backendUrl + '/create-pdf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(testData),
    })
      .then((response) => response.json())
      .then((data) => {
        fetch(backendUrl + data.url)
          .then((response) => response.blob())
          .then((blob) => {
            const fileName = data.url.substring(data.url.lastIndexOf('/') + 1);
            saveAs(blob, fileName);
          })
          .then(() => {
            //setTimeout(() => { den här raden och två ner kan man aktivera för att simulera laddning
            setLoading(false);
            //}, 2000);
          });
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  const updateQuantity = (value) => {
    setQuantity(value);
  };

  const updatePrice = (value) => {
    setPrice(value);
  };

  return (

    <div className="App" >
      <Helmet>
        <title>Skapa Faktura</title>
      </Helmet>

      <Box sx={{ backgroundColor: '#f7ef80', marginBottom: '16px', padding: '16px', textAlign: 'center', marginLeft: 0, paddingLeft: 0 }}>
        <Typography variant="h1" sx={{ color: 'black' }}>
          SKAPA FAKTURA
        </Typography>
      </Box>
      <Typography align="center">
        Välkommen till skapafaktura.se! Skapa Faktura är en enkel och gratis tjänst för att skapa fakturor.
      </Typography>
      <Typography align="center">
        Fyll i dina företagsuppgifter och generera din faktura på ett ögonblick. Skapa professionella fakturor med lätthet!
      </Typography>
      <form>
        <Box sx={{ backgroundColor: '#fcfbdb', padding: '16px' }}>
          <h2>Dina företagsuppgifter</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Företagsnamn"
                value={yourCompanyName}
                onChange={(e) => setYourCompanyName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Adress"
                value={yourAddress}
                onChange={(e) => setYourAddress(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Postnummer och ort"
                value={yourPostcodeAndCity}
                onChange={(e) => setYourPostcodeAndCity(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Organisationsnummer"
                value={yourOrgNr}
                onChange={(e) => setYourOrgNr(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Bankgiro"
                value={yourBank}
                onChange={(e) => setYourBank(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Momsreg. nr"
                value={yourVATnr}
                onChange={(e) => setYourVATnr(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ backgroundColor: '#fcfbdb', padding: '16px' }}>

          <h2>Faktureringsadress och villkor (företaget som ska få fakturan)</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Företagsnamn"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Adress"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Postnummer och ort"
                value={postcodeAndCity}
                onChange={(e) => setPostcodeAndCity(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Fakturanummer"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="date"
                label="Förfallodag"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                label="Betalningsvillkor (antal dagar)"
                value={paymentTerms}
                onChange={(e) => setPaymentTerms(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="vat-label" shrink>
                  Momsprocent
                </InputLabel>
                <Select
                  labelId="vat-label"
                  label="Momsprocent" // Lägg till detta
                  value={vat}
                  onChange={(e) => setVat(e.target.value)}
                >
                  <MenuItem value={0}>0%</MenuItem>
                  <MenuItem value={6}>6%</MenuItem>
                  <MenuItem value={12}>12%</MenuItem>
                  <MenuItem value={25}>25%</MenuItem>
                </Select>
              </FormControl>
            </Grid>


          </Grid>
        </Box>
        <br />

        <Box sx={{ backgroundColor: '#fcfbdb', padding: '16px' }}>
          <h2>Fakturauppgifter</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Beskrivning"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                label="Antal"
                value={quantity}
                onChange={(e) => updateQuantity(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                label="á pris"
                value={price}
                onChange={(e) => updatePrice(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ backgroundColor: '#fcfbdb', padding: '16px' }}>
          <Button
            onClick={(event) => {
              addRow(event);
            }}
            variant="contained"
            disabled={!(description && quantity && price) || invoiceRows.length >= 15}
            style={{
              backgroundColor: invoiceRows.length >= 15 ? '#ccc' : '#f7ef80',
              color: invoiceRows.length >= 15 ? '#999' : '#000000'
            }}
          >
            Lägg till rad
          </Button>


        </Box>
      </form>
      {showTable && (
        <Box sx={{ padding: '16px' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Beskrivning</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Antal</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Pris</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Belopp</TableCell>
                  <TableCell></TableCell> {/* Tom cell för att matcha 'Ta bort'-cellen i TableBody */}
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell>{row.quantity * row.price}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          const newInvoiceRows = [...invoiceRows];
                          newInvoiceRows.splice(index, 1);
                          setInvoiceRows(newInvoiceRows);
                        }}
                      >
                        <DeleteIcon sx={{ color: 'black' }} />
                      </Button>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={(event) => {
              createPdf(event);
            }}
            variant="contained"
            style={{ backgroundColor: '#f7ef80', color: '#000000', fontWeight: 'bold', fontSize: '18px', marginTop: '24px' }}
            disabled={loading} // Knappen inaktiveras medan fakturan skapas
          >
            {loading ? <CircularProgress size={24} /> : "Skapa din faktura"}
          </Button>
        </Box>

      )}
      <footer>
        <Box
          className="contact-section"
          sx={{
            backgroundColor: '#f7ef80',
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div>
            <img src={pineappleImage} alt="Bildbeskrivning" style={{ width: '50px', height: 'auto', marginRight: '16px' }} />
          </div>
          <div>
            <Typography sx={{ textAlign: 'center' }}>
              Skapafaktura.se är en tjänst från Pineapple Consulting
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              Förbättringsförslag? Maila markus.ludvigsson (at) kvadrat.se
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              Vill du annonsera här? Hör av dig!
            </Typography>

          </div>
        </Box>
      </footer>
    </div >
  );
}

export default App;